@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  
  .fade-in {
    animation: fade-in 0.5s ease-in-out;
  }
  
  .fade-out {
    animation: fade-out 0.5s ease-in-out;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader {
    animation: spin 1s linear infinite;
  }

  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .scrollbar-thin::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  
  .scrollbar-thumb-white::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }
  
  .scrollbar-track-transparent::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .tooltip-info-icon:hover + .tooltip-message {
    display: block;
  }
  
  .tooltip-message {
    z-index: 10;
  } 

  .gutter {
    scrollbar-gutter: both-edges;
  }

  .vxcontainer {
    width: 600px; /* adjust the width as needed */
    height: 400px; /* adjust the height as needed */
    margin: auto;
  }

  .pan-zoom-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-container-index {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Background color with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is rendered above other content */
  }
  
  .modal-content {
    background-color: #fff;
    /* padding: 2rem; */
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    z-index: 1001; /* Ensure the modal content is rendered above the modal container */
  }